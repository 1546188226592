import React from 'react';
import ReactDOM from 'react-dom';
import querystring from 'query-string';
import { Provider } from 'react-redux';
import { Link, Redirect, Route, Router, NavLink, Switch } from 'react-router-dom';
import axios from 'axios';
import FederationTestPage from './components/federation-setup/FederationTestPage';
import AppContainer from './AppContainer';
import store, { history } from './store';
import { unregister } from './registerServiceWorker';
import './index.scss';
import './styles/app/globals.scss';
import "@netapp/bxp-design-system-react/dist/index.css";
import ErrorPage from './components/misc/ErrorPage';
import NetAppFederationEula from './components/misc/NetAppFederationEula';
import StandaloneHeader from './components/standalone-header/StandaloneHeader';
import { removeLoader } from './Loader';
import { trackPageView } from "./utils/mrTracker";
import RoutingContext from "./components/RoutingContext";
import { DialogContainer } from "./components/dialog/Dialog";
import "./ServiceIcons.scss";
import { setInnerTabLinkProvider, ThemeProvider, initModalTargetListener } from "@netapp/bxp-design-system-react";

setInnerTabLinkProvider({Link, NavLink});
const auth0 = require('auth0-js');
const Auth = require('./Auth/Auth.js').default;

window.auth0 = auth0;

initModalTargetListener();

const search = querystring.parse(window.location.search);
if (search.redirectBack === 'true') {
    window.history.replaceState(null, "", `/?${querystring.stringify({
        ...search,
        redirectBack: undefined,
        redirectToLogin: true
    })}`);
    window.history.back();
} else {
    window.auth = new Auth({
        clientID: window.__auth__.clientId,
        domain: window.__auth__.domain,
        audience: window.__auth__.audience
    }, {baseUrl: window.location.origin});
    window.testAppAuth = new Auth({
        clientID: window.__auth__.testAppClientId,
        domain: window.__auth__.domain,
        audience: window.__auth__.audience
    }, {baseUrl: window.location.origin});
    axios.defaults.baseURL = window.__react_app_portal_backend_base_url;

    window.axios = axios;

    store.dispatch({ type: "AUTH:REFRESH-SSO" });

    history.listen(location => {
        trackPageView({ location });
        window.scrollTo(0, 0);
    });

    if (search.__hsfp || search.__hssc || search.__hstc) {
        delete search.__hsfp;
        delete search.__hssc;
        delete search.__hstc;
        const searchString = querystring.stringify(search);
        history.replace(window.location.pathname + (searchString ? `?${searchString}` : ''));
    }

    const Misc = () => {
        removeLoader(true);
        return <React.Fragment>
            <Switch>
                <Route path="/misc/federation-support" exact render={() => <Redirect to="/federation-setup"/>}/>
                <Route path="/misc/error-page" exact component={ErrorPage}/>
                <Route path="/misc/netapp-federation-eula" exact render={() => <NetAppFederationEula/>}/>
                <Route path="/misc" render={() => <Redirect to="/"/>}/>
            </Switch>
        </React.Fragment>;
    };

    const RedirectToFederatedConnectionTest = () => {
        const paramsForLoginPage = { service: 'federated-connection-test', email: search.email };
        const extra = paramsForLoginPage ? `?paramsForLoginPage=${window.btoa(JSON.stringify(paramsForLoginPage))}` : "";

        window.testAppAuth.loginRedirect({
            responseType: 'token id_token',
            audience: "https://api.cloud.netapp.com",
            scope: 'openid profile',
            redirectUri: `${window.location.origin}/federated-connection-test${extra}`
        });

        return <div>Redirecting... </div>;
    };

    const MainSwitch = props => (<RoutingContext.Provider value={props}>
        <Switch>
            {/* All redirections to misc need to be added to the authMiddleware to avoid unnecessary authentication */}
            <Route path="/error-page" exact
                   render={() => <Redirect to={`/misc/error-page${window.location.search}`}/>}/>
            <Route path="/styleguide" render={() => window.location.href=`${window.location.origin}/styleguide/index.html?path=${window.location.pathname}`}/>
            <Route path="/header" exact render={() => <StandaloneHeader/>}/>
            <Route path="/misc" component={Misc}/>
            <Route path="/federated-connection-test" render={() => <FederationTestPage/>}/>
            <Route path="/redirect-to-federated-connection-test" render={() => <RedirectToFederatedConnectionTest/>}/>
            <Route path="/" render={() => <AppContainer/>}/>
        </Switch>
        <DialogContainer/>
    </RoutingContext.Provider>);

    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <ThemeProvider theme="light" isRoot={true}>
                    <Route path="/" component={MainSwitch}/>
                </ThemeProvider>
            </Router>
        </Provider>,
        document.getElementById('root')
    );
}

unregister();
